/**
 * @note
 * Since we are using module federation to shared modules across applications, we have to asynchronously
 * load our application to avoid errors. This is the recommended way according to the Webpack docs.
 *
 * @see https://webpack.js.org/concepts/module-federation/#troubleshooting
 */

// eslint-disable-next-line no-unused-expressions
import('./bootstrap');
